import React from 'react';

const About = () => {
  return (
    <section id='about' className="min-h-screen flex justify-center items-center bg-[#e5e7eb]">
      <div className="container mx-auto px-5 pb-10">
        <div className="flex-col md:flex-row md:flex md:gap-10 items-center justify-center">
          <div className="md:w-1/2">
            <img
              src="/images/about.webp"
              alt="Tentang Kami - Prioritas Ketelitian dan Keamanan"
              className="mb-8 md:mb-0 rounded-lg mx-auto"
              loading="lazy"
              width="700"
              height="500"
            />
          </div>
          <div className="md:w-1/2">
            <h1 className="text-2xl md:text-3xl xl:text-4xl text-orange-500 font-bold mb-6">Tentang Kami</h1>
            <p className="text-base md:text-lg xl:text-xl text-black mb-4">
              Kami Prioritaskan Ketelitian dan Keamanan dalam Setiap Pekerjaan
              <br />
              Dalam setiap proyek yang kami tangani, termasuk layanan pemasangan kelistrikan, tukang batu, pemasangan plafon, dan pengelasan, kami memastikan kualitas dan keandalan yang optimal. Ketelitian dan keamanan adalah fokus utama kami untuk memberikan hasil terbaik kepada klien.
            </p>
            <h2 className="text-xl md:text-2xl xl:text-3xl text-orange-500 font-semibold mb-4">Komitmen Kami terhadap Kualitas</h2>
            <p className="text-base md:text-lg xl:text-xl text-black mb-4">
              Kami terus berkomitmen untuk memberikan layanan yang unggul dan memenuhi standar industri tertinggi. Dengan pengalaman bertahun-tahun, tim kami siap menghadapi berbagai tantangan dan memastikan setiap proyek dikerjakan dengan ketelitian dan profesionalisme.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
