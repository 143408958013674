import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Services from './components/services/Services';
import Footer from './components/Footer';
import Wa from './components/Wa';

function App() {
  return (
    <div>
      <Header />
      <Hero />
      <About />
      <Services />
      <Footer />
      <Wa />
    </div>
  );
}

export default App;
