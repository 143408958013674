// src/components/WhatsAppIcon.js
import React from 'react';

const Wa = () => {
  return (
    <a
      href="https://wa.me/6289615294045" // Ganti dengan nomor WhatsApp yang sesuai
      className="fixed bottom-4 right-4 p-3 rounded-full z-10 shadow-lg transition-colors duration-300"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="/images/wa.webp" // Path relatif dari folder public
        alt="WhatsApp"
        className="w-12 h-12" // Atur ukuran sesuai kebutuhan
      />
    </a>
  );
};

export default Wa;
