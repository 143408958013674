import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import './Services.css';
import { EffectCoverflow, Pagination } from 'swiper/modules';

const Services = () => {
    const jasa = [
        {
            id: 1,
            jasa: "Layanan Pengelasan",
            detail: "Kami menawarkan layanan pengelasan berkualitas tinggi di Jabodetabek, mencakup kanopi, tralis, rolling door, dan lainnya. Teknik dan peralatan modern kami memastikan hasil pengelasan yang presisi, kuat, dan tahan lama.",
            image: "/images/img1.webp",
            link: "https://wa.me/6289615294045?text=Halo%20saya%20tertarik%20dengan%20layanan%20pengelasan"
        },
        {
            id: 2,
            jasa: "Layanan Pemasangan Plafon",
            detail: "Kami menyediakan pemasangan plafon berkualitas di Jabodetabek, yang dapat meningkatkan estetika dan kenyamanan ruangan. Tim ahli kami siap memasang plafon sesuai kebutuhan dan gaya interior Anda.",
            image: "/images/img2.webp",
            link: "https://wa.me/6289615294045?text=Halo%20saya%20tertarik%20dengan%20layanan%20pemasangan%20plafon"
        },
        {
            id: 3,
            jasa: "Layanan Tukang Batu",
            detail: "Kami menyediakan tukang batu profesional di Jabodetabek untuk berbagai proyek konstruksi. Dari pemasangan bata hingga pembangunan dinding, hasil kami kokoh dan tahan lama.",
            image: "/images/img3.webp",
            link: "https://wa.me/6289615294045?text=Halo%20saya%20tertarik%20dengan%20layanan%20tukang%20batu"
        },
        {
            id: 4,
            jasa: "Layanan Pemasangan Kelistrikan",
            detail: "Kami menyediakan instalasi kelistrikan berkualitas tinggi di Jabodetabek. Tim kami siap membantu dengan berbagai proyek kelistrikan, dari instalasi baru hingga perbaikan dan pemeliharaan.",
            image: "/images/img4.webp",
            link: "https://wa.me/6289615294045?text=Halo%20saya%20tertarik%20dengan%20layanan%20pemasangan%20kelistrikan"
        },
        {
            id: 5,
            jasa: "Layanan Pemasangan Lampu Neon",
            detail: "Kami menyediakan pemasangan lampu neon di Jabodetabek untuk kebutuhan dekoratif dan komersial. Tim kami memastikan instalasi yang aman dan estetis untuk ruang Anda.",
            image: "/images/img5.webp",
            link: "https://wa.me/6289615294045?text=Halo%20saya%20tertarik%20dengan%20layanan%20pemasangan%20lampu%20neon"
        }
    ];

    return (
        <section id='services' className="min-h-screen flex flex-col items-center justify-center bg-[#1f2937] p-4">
            <h1 className='text-white text-6xl font-bold'>Jasa Kami</h1>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={1}
                spaceBetween={50}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 300,
                    modifier: 1,
                    slideShadows: false,
                }}
                loop={true}
                pagination={{ clickable: true }}
                modules={[EffectCoverflow, Pagination]}
                className="mySwiper"
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                }}
            >
                {jasa.map(item => (
                    <SwiperSlide key={item.id}>
                        <div className="bg-gray-800 p-6 rounded-lg shadow-lg border-2 flex flex-col items-center transform transition-transform duration-300 md:hover:scale-105 ">
                            {item.image ? (
                                <img src={item.image} alt={item.jasa} className="h-48 w-full object-cover rounded-md mb-4" />
                            ) : (
                                <div className="h-52 bg-gray-200 w-full rounded-md mb-4"></div>
                            )}
                            <h3 className="text-xl font-semibold mb-2 text-white">{item.jasa}</h3>
                            <p className="text-gray-200 mb-4 max-h-24 overflow-y-auto text-ellipsis">{item.detail}</p>
                            <a 
                                href={item.link} 
                                className="inline-block px-5 xs:px-6 md:px-9 rounded-md py-3 md:py-4 duration-300 ease-in-out hover:bg-[#ff9735] font-semibold bg-orange-500 text-white"
                                aria-label={`Lihat detail tentang ${item.jasa}`}>
                                Pesan Sekarang
                            </a>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    );
};

export default Services;
