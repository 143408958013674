import React from 'react';

const Hero = () => {
  return (
    <section id='home' className="offer-img z-20 flex justify-center min-h-screen items-center relative px-3 h-screen">
      <img 
        src="/images/hero.webp" 
        alt="Latar Belakang Hero - Berkah Hadi, Kualitas dan Ketelitian dalam Konstruksi dan Energi" 
        className="absolute top-0 left-0 w-full h-full object-cover z-10"
      />
      <div className="z-30 text-center relative">
        <h1 className="text-2xl md:text-3xl lg:text-4xl xl:text-5xl mb-6 font-semibold text-white">
          Welcome To <span className="text-orange-500">Berkah Hadi</span>
        </h1>
        <p className="text-base md:text-lg lg:text-xl xl:text-2xl text-white mb-10">
          Kualitas dan Ketelitian dalam Setiap Layanan Konstruksi dan Energi
        </p>
        <a 
          href="https://wa.me/6289615294045" 
          className="inline-block px-5 xs:px-6 md:px-9 rounded-md py-3 md:py-4 duration-300 ease-in-out hover:bg-[#ff9735] font-semibold bg-orange-500 text-white"
          title="Pesan Sekarang layanan konstruksi dan energi dari Berkah Hadi" 
          aria-label="Pesan Sekarang layanan kami">
          Pesan Sekarang
        </a>
      </div>
    </section>
  );
};

export default Hero;
