import React from 'react';

const Footer = () => {
  return (
    <footer id='contact' className="bg-orange-500">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <a href="/" className="flex items-center" title="Berkah Hadi - Layanan Konstruksi di Jabodetabek">
              <img src="/images/logo.webp" alt="Logo Berkah Hadi - Layanan Konstruksi di Jabodetabek" className="scale-110" />
            </a>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-50 uppercase">Resources</h2>
              <ul className="text-gray-50 font-medium">
                <li className="mb-4">
                  <a href="home" className="hover:underline" title="Kunjungi Website Kami">Website</a>
                </li>
                <li>
                  <a href="services" className="hover:underline" title="Layanan yang Kami Tawarkan">Services</a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-50 uppercase">Message us</h2>
              <ul className="text-gray-50 font-medium">
                <li className="mb-4">
                  <a href="mailto:wadianto80@gmail.com" className="hover:underline" title="Kunjungi Email Kami">Email</a>
                </li>
                <li>
                  <a href="https://wa.me/6289615294045" className="hover:underline" title="Kunjungi WhatsApp Kami">WhatsApp</a>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-50 uppercase">Legal</h2>
              <ul className="text-gray-50 font-medium">
                <li className="mb-4">
                  <span className="hover:underline" title="Kebijakan Privasi Kami">Privacy Policy</span>
                </li>
                <li>
                  <span className="hover:underline" title="Syarat & Ketentuan Kami">Terms & Conditions</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-50 sm:text-center">© 2023 <span className="hover:underline" title="Berkah Hadi - Hak Cipta 2023">Berkah Hadi</span>. All Rights Reserved.</span>
          <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
            <span className="text-gray-50 hover:text-white" title="Kunjungi Email Kami">
              <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                <path fillRule="evenodd" d="M7.75 0H.25v18.75h5.56V11.25H3.28V7.812h2.53V6.25a3.44 3.44 0 013.44-3.437H8V0H7.75z"/>
              </svg>
              <span className="sr-only">Email page</span>
            </span>
            <span className="text-gray-50 hover:text-white" title="Kunjungi WhatsApp Kami">
              <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M10 0a10 10 0 100 20A10 10 0 0010 0zm5.32 7.78l-4.34 6.66-2.98-2.98a.85.85 0 010-1.2.84.84 0 011.2 0l2.12 2.12 3.66-5.62a.85.85 0 111.38.98z" clipRule="evenodd"/>
              </svg>
              <span className="sr-only">WhatsApp page</span>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
